import React from 'react';

export const ButtonCancelar = ({clickCancelar}) => <div className="btn btn-crud m-1" onClick={() => clickCancelar()}>Cancelar</div>

const ButtonNew = ({clickAddNew}) => <div className="btn btn-crud m-1 btn-sm" onClick={() => clickAddNew()}>Nuevo</div> 


export const HeaderCms = ({title, clickAddNew}) => 
    <header className="w-100 d-flex justify-content-between align-items-center ">
		<h5>{title}</h5>
		<ButtonNew clickAddNew={clickAddNew} />
	</header>

export const ButtonActualizar = ({clickSaveNew}) => //clickSaveNew.bind(this, item, i)
<div className="btn btn-crud  agregar_envio m-1" onClick={clickSaveNew} >Guardar</div>

export const ButtonSave = ({clickSaveEdit}) => 
<div className="btn btn-crud  agregar_envio m-1" onClick={clickSaveEdit} >Actualizar</div>

const ButtonMenos = ({botonLimit}) => <div class="page-link" onClick={botonLimit.bind(this, "menos")}aria-label="Previous">
	<span aria-hidden="true">&laquo;</span>
	<span class="sr-only">Previous</span>
</div>

const ButtonMas = ({botonLimit}) =>  <div class="page-link" onClick={botonLimit.bind(this, "mas")} aria-label="Next">
	<span aria-hidden="true">&raquo;</span>
	<span class="sr-only">Next</span>
</div> 
const ContadorLimite = ({contadorLim}) => <div className="page-link">{contadorLim}</div>

export const TableTd = ({propertytd}) => <p className="paink">{propertytd}</p>


const SelectLimiteConst = ({selectLimite}) => 
    <select name="" id="" className="page-link ml-4" onChange={selectLimite}>
		<option value="5">5</option>
		<option value="10">10</option>
		<option value="15">15</option>
		<option value="20">20</option>
		<option value="25">25</option>
	</select>


export const HeaderLimite = ({botonLimit, selectLimite, contadorLim}) => 
    <nav class="w-100 navbar navbar-expand">
        <div class="container-fluid">
            <div class="navbar-header">
                
            </div>
            <div class="navbar" id="bs-example-navbar-collapse-1">
                <div class="nav navbar-nav ml-auto pagination pagination-sm">
                    <div class="nav-item">
                        <ButtonMenos botonLimit={botonLimit} />
                    </div>
                    <div class="nav-item">
                        <ContadorLimite contadorLim={contadorLim} />
                    </div>
                    <div class="nav-item">
                        <ButtonMas botonLimit={botonLimit}/>
                    </div>
                    
                    <div class="nav-item">
                        <SelectLimiteConst selectLimite={selectLimite} />
                    </div>
                </div>
            </div>
        </div>
    </nav>