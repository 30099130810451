import "@animxyz/core";
import React, { useState } from "react";
import { XyzTransition, XyzTransitionGroup } from "@animxyz/react";

//import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Carousel  } from 'react-bootstrap';

import { NeonboComponente } from './neonbo.js';
import { BlogComponent } from './blog.js';
import './style_v.css';
import { DificaComponente } from './difica.js';

import imgYellow from './img/fondo-perfil-html.jpg';
import imgGreen from './img/fondo-perfil-verdel.png';
import imgBlue from './img/fondo-perfil-azul.png';

import fondoImg from '../img/fondo-body.jpg';
import fondoImgDos from '../img/fondotren-blur.jpg';


class CurriculoDav extends React.Component {
    constructor(props){
      super(props);
      
      this.positionExp = this.positionExp.bind(this);
      this.state = {
        positionExpD:0,
        positionExpCont:0,
        showActiveCv: true,
        clickede: false,
		  	
      }
		  this.handleSelect = this.handleSelect.bind(this);
    }
    handleSelect(){
		
      this.setState({
        clickede: false
      }); 
      
      clearTimeout(this.uno);
      clearTimeout(this.dos);
      var that = this;
  
       this.uno = setTimeout(function(){
        
        that.setState({
          clickede: true
        });
      },100);
  
      this.dos = setTimeout(function(){
        
         that.setState({
           clickede: false
         });
       },5000); 
  
         
    }
    componentDidMount() {
      document.body.style.backgroundImage = 'url('+fondoImg+')';

      this.setState({
        clickede: true
      }); 
  
      this.interval = setInterval(
        () => this.setState({ index: (this.state.index + 1) % 7 }), 
        3000
      );
  
      var that = this;
      setTimeout(function(){
       
         that.setState({
           clickede: false
         });
        },4500); 
    }
    positionExp(s) {
   
      var idPosition = parseInt(s.target.id);
      var sp = document.querySelector(".experience");
      sp.classList.remove("anime1");
      sp.classList.remove("anime2");
      sp.classList.remove("anime3");
      
      sp.classList.add("anime"+idPosition);
      this.setState({
        showActiveCv: false,
        positionExpD: idPosition,
      });
      
      var that = this;
  
      setTimeout(function(){
        that.setState({
          showActiveCv: true,
          positionExpCont: idPosition
        });
      },500);
      
  
   
    }
    componentWillUnmount(){
      clearInterval(this.interval);
      document.body.style.backgroundImage = 'url('+fondoImgDos+')';
    }
  
    render() {
      const {positionExpCont,  showActiveCv, clickede} = this.state;
  
      var optionCv = '';
    if(positionExpCont===2){
      optionCv = NeonboComponente;
  
    } else if (positionExpCont===3){
      optionCv = BlogComponent;
  
    } else if(positionExpCont===1) {
  
      optionCv = DificaComponente;
  
    } else {
      optionCv = DificaComponente;
    }
      return (
          <Container className="p-0">
            
              <Row className="p-0">
                <Col className="p-0">
                  <div className="p-cv ">
                    <h6>Perfil: </h6>
                  </div>
                </Col>
              </Row>
              <Row className="">
                <Col className=" mt-2 mb-2 p-md-4  p-lg-4 p-sm-0 p-xl-0">
                  
                  <Carousel keyboard="true"  interval={7500} indicators={false} controls={true} slide="true" pause={true} touch="true" onSelect={this.handleSelect} >
                    <Carousel.Item>
                      <img
                        className="d-block w-100 img-carrucel"
                        src={imgYellow}
                        alt="First slide"
                      />
                      <Carousel.Caption>
                        <XyzTransitionGroup appear duration="auto">
        
                          {clickede && <div class="page-wrap">
        
                            <div class="page-features " xyz="fade front-3 flip-down-50% duration-10 stagger-5">
                              <div class="feature-item xyz-nested"><h5>Maquetador</h5></div>
                              <div class="feature-item xyz-nested"><small>De sitios web, widgets y pdf.</small></div>
                            </div>
                            <div  class="w-100 page-footer d-flex justify-content-md-center " xyz="fade front-3 flip-down-100% duration-9 stagger-5 perspective-5 big-100% thick-100% tall-100% skew-up-5 skew-right-5 delay-15">
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around " xyz="inherit  down flip-up-25% ">
                                <div class="m-auto to dos-circ">
                                  <div class="oe dos-circ">
                                    <div class="tit-centro circ-neon tit">
                                      <small>Css3 - Sass</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around "  xyz="inherit  flip-up flip-left">
                                <div class="m-auto to1 dos-circ">
                                  <div class="oe1 dos-circ">
                                    <div class="tit-centro circ-neon tit1">
                                      <small>HTML5</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around "  xyz="inherit  flip-up flip-left">
                                <div class="m-auto to2 dos-circ">
                                  <div class="oe2 dos-circ">
                                    <div class="tit-centro circ-neon tit2">
                                      <small>JS</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around "  xyz="inherit  flip-up flip-left">
                                <div class="m-auto to3 dos-circ">
                                  <div class="oe3 dos-circ">
                                    <div class="tit-centro circ-neon tit3">
                                      <small>Flutter</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around "  xyz="inherit  flip-up flip-left">
                                <div class="m-auto to4 dos-circ">
                                  <div class="oe4 dos-circ">
                                    <div class="tit-centro circ-neon tit4">
                                      <small>Pdf - php</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>}
                      
                          
                        </XyzTransitionGroup>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
        
                      <img
                        className="d-block w-100 img-carrucel"
                        src={imgGreen}
                        alt="Second slide"
                      />
                    
                      <Carousel.Caption>
                      
        
                        <XyzTransitionGroup appear duration="auto">
                      
                          {clickede && <div class="page-wrap">
        
                            <div class="page-features " xyz="fade up stagger-3 ease-in">
                              <div class="feature-item xyz-nested"><h5>Elaboración de servicios Web RESTful </h5></div>
                              <div class="feature-item xyz-nested"><small>Protocolo Json.</small></div>
                            </div>
                            <div  class="w-100 page-footer d-flex justify-content-md-center" xyz="fade front-3 flip-down-100% duration-9 stagger-5 perspective-5 big-100% thick-100% tall-100% skew-up-5 skew-right-5 delay-15">
                              <div class="footer-item xyz-nested iconos-perfil justify-content-around " >
                                <div class="m-auto to dos-circ">
                                  <div class="oe dos-circ">
                                    <div class="tit-centro circ-neon tit">
                                      <small>Php</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="footer-item xyz-nested iconos-perfil justify-content-around "  >
                                <div class="m-auto to1 dos-circ">
                                  <div class="oe1 dos-circ">
                                    <div class="tit-centro circ-neon tit1">
                                      <small>Mysql</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="footer-item xyz-nested iconos-perfil justify-content-around " >
                                <div class="m-auto to2 dos-circ">
                                  <div class="oe2 dos-circ">
                                    <div class="tit-centro circ-neon tit2">
                                      <small>Firebase</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="footer-item xyz-nested iconos-perfil justify-content-around "  xyz="inherit  flip-up flip-left">
                                <div class="m-auto to3 dos-circ">
                                  <div class="oe3 dos-circ">
                                    <div class="tit-centro circ-neon tit3">
                                      <small>Python</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="footer-item xyz-nested iconos-perfil justify-content-around "  xyz="inherit  flip-up flip-left">
                                <div class="m-auto to4 dos-circ">
                                  <div class="oe4 dos-circ">
                                    <div class="tit-centro circ-neon tit4">
                                      <small>Ruby</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            
                            </div>
                          </div>}
                      
                          
                        </XyzTransitionGroup>
                
                      </Carousel.Caption>
        
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100 img-carrucel"
                        src={imgBlue}
                        alt="Third slide"
                      />
                    
                      <Carousel.Caption>
                        
                    
                        <XyzTransitionGroup appear duration="auto">
        
                          {clickede && <div class="page-wrap">
        
                            <div class="page-features " xyz="fade small duration-9 stagger-5">
                              <div class="feature-item xyz-nested"><h5>Cms CRUD</h5></div>
                              <div class="feature-item xyz-nested"><small>Y desarrollo de plantillas Wordpres.</small>.</div>
                            </div>
                            
                            <div  class="w-100 page-footer d-flex justify-content-md-center " xyz="fade down-5 rotate-right-50% stagger-3 big-100% skew-right-5 skew-up-5 perspective-5  duration-6 delay-15">
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around ">
                                <div class="m-auto to dos-circ">
                                  <div class="oe dos-circ">
                                    <div class="tit-centro circ-neon tit">
                                      <small>Laravel</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around ">
                                <div class="m-auto to1 dos-circ">
                                  <div class="oe1 dos-circ">
                                    <div class="tit-centro circ-neon tit1">
                                      <small>Codeigniter</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around ">
                                <div class="m-auto to2 dos-circ">
                                  <div class="oe2 dos-circ">
                                    <div class="tit-centro circ-neon tit2">
                                      <small>React</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around ">
                                <div class="m-auto to3 dos-circ">
                                  <div class="oe3 dos-circ">
                                    <div class="tit-centro circ-neon tit3">
                                      <small>Mysql y php</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around ">
                                <div class="m-auto to4 dos-circ">
                                  <div class="oe4 dos-circ">
                                    <div class="tit-centro circ-neon tit4">
                                      <small>Javascript</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          
                            </div>}
                      
                          
                        </XyzTransitionGroup>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100 img-carrucel"
                        src={imgBlue}
                        alt="Third slide"
                      />
                      
                      <Carousel.Caption>
                        <XyzTransitionGroup appear duration="auto">
                          {clickede && <div class="page-wrap">
        
                            <div class="page-features " xyz="fade left stagger duration-10 stagger-2.5">
                              <div class="feature-item xyz-nested"><h5>Simuladores financieros y test fit</h5></div>
                              <div class="feature-item xyz-nested"><small>Algoritmos de ordenación y combinaciones</small></div>
                              
                            </div>
                            <div  class="w-100 page-footer d-flex justify-content-md-center " xyz="fade front-3 flip-down-100% duration-9 stagger-5 perspective-5 big-100% thick-100% tall-100% skew-up-5 skew-right-5 delay-15">
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around ">
                                <div class="m-auto to dos-circ">
                                  <div class="oe dos-circ">
                                    <div class="tit-centro circ-neon tit">
                                      <small>Php</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around ">
                                <div class="m-auto to1 dos-circ">
                                  <div class="oe1 dos-circ">
                                    <div class="tit-centro circ-neon tit1">
                                      <small>JavaScript</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around ">
                                <div class="m-auto to2 dos-circ">
                                  <div class="oe2 dos-circ">
                                    <div class="tit-centro circ-neon tit2">
                                      <small>React</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around ">
                                <div class="m-auto to3 dos-circ">
                                  <div class="oe3 dos-circ">
                                    <div class="tit-centro circ-neon tit3">
                                      <small>jQuery</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col footer-item xyz-nested iconos-perfil justify-content-around ">
                                <div class="m-auto to4 dos-circ">
                                  <div class="oe4 dos-circ">
                                    <div class="tit-centro circ-neon tit4">
                                      <small>Javascript</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          
                          </div>}
                        </XyzTransitionGroup>
                      </Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                   
                   
                  
                </Col>
              </Row>
              <Row className="p-0">
                <Col className="p-0">
                  <div className="p-cv  mb-3">
                    <h6>Experiencia profesional:</h6>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div class="card-cv  exp-1" >
                    <div class="card-body">
                      <h5 class="card-title">Inmobiliaria</h5>
                      <p class="card-text white-cv">Desde Marzo 2017.</p>
                      <div  class="btn-cv" id="1" onClick={this.positionExp}>Abrir</div>
                    </div>
                  </div>
                </Col>
  
                <Col >
                  <div class="card-cv  exp-2" >
                    
                    <div class="card-body">
                      <h5 class="card-title">Neonbo </h5>
                      <p class="card-text white-cv">Marzo del 2013 al 2016</p>
                      <div  class="btn-cv" id="2" onClick={this.positionExp}>Abrir</div>
                    </div>
                  </div>
                </Col>
  
                <Col >
                  <div class="card-cv  exp-3" >
                    <div class="card-body">
                      <h5 class="card-title">Blog </h5>
                      <p class="card-text white-cv">Marzo del 2013 al 2016.</p>
                      <div  class="btn-cv" id="3" onClick={this.positionExp}>Abrir</div>
                    </div>
                  </div>
                </Col>
               
              </Row>
              <Row>
                <Col className="mt-2">
                  <div id="experience" className="experience">
                    <i className="fa fa-2x fa-caret-down"></i>
                  </div>
                  
                </Col>
              </Row>
              <div className= {`slider-cv  ${showActiveCv ? "slider-cv-anima animated fadeIn  dummy--active" : "slider-cv-anima-no animated flipOutY dummy"}`}>
              {
                optionCv
              }
              </div>
              <Row className="p-0">
                <Col className="col-12 mt-4 p-0">
                  <div className="p-cv  mb-3">
                    <h6 className="card-title">Estudios</h6>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="col-12 ">
                  <div className="card-cv-content" >
                    <div className="card-body">
                      
                      <p className="card-text">
                        2008 – 2013 IPN Esime Culhuacán, Ingeniería Comunicaciones y Electrónica.  
                      </p>
                      <p className="card-text">
                        2000 – 2003 Conalep Xochimilco, Electrónica Industrial 
                      </p>
                     
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
         
      
      )
    }
  }
  
  export {CurriculoDav}