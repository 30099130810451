export let usuariosTable = 
    [
        {"propiedad":"id",
        "valor":"",
        "title":"id",
        "type":"text",
        "show_table":false,
        "modificar_edit":false,
        "place_holder": "nombre**",
            "valida_campo_extra": "20" },

        {"propiedad":"nombre_usuario",
        "valor":"",
        "title":"Correo",
        "type":"email",
        "show_table":true,
        "modificar_edit":true,
        "place_holder": "nombre**",
            "valida_campo_extra": "230" },

        {"propiedad":"nombre_completo",
        "valor":"",
        "title":"Nombre",
        "type":"text",
        "show_table":true,
        "modificar_edit":true,
        "place_holder": "nombre**",
            "valida_campo_extra": "230" },

        {"propiedad":"password",
        "valor":"",
        "title":"Password",
        "type":"text",
        "show_table":false,
        "modificar_edit":true,
        "place_holder": "nombre**",
            "valida_campo_extra": "40" },

        {"propiedad":"nivel",
        "valor":"",
        "title":"Nivel",
        "type":"select",
        "show_table":true,
        "modificar_edit":true,
        "place_holder": "nombre**",
            "valida_campo_extra": "60" },

        {"propiedad":"telefono",
        "valor":"",
        "title":"Telefono",
        "type":"text",
        "show_table":false,
        "modificar_edit":false,
        "place_holder": "nombre**",
        "valida_campo_extra": "70" },

        {"propiedad":"img_user",
        "valor":"",
        "title":"Imagen usuario",
        "type":"file",
        "show_table":false,
        "modificar_edit":true,
        "place_holder": "nombre**",
        "valida_campo_extra": "70" },      

        {"propiedad":"sexo",
        "valor":"",
        "title":"Sexo",
        "type":"radio",
        "show_table":false,
        "modificar_edit":true,
        "place_holder": "nombre**",
        "valida_campo_extra": "70" }, 
    ];

