
import React, { useState } from 'react';
import './css/animated.css';
import './css/flipx.css';
import './css/press-scroll.css';

/* 
class Anime1 extends React.Component {
    render(){
        return (
           
            <div class="d-inline-block background-item ">
                <div class="img-scroll">     
                    <img alt="ss" class="img-fluid img-b" src={this.props.img} />
                </div>
                <div class="item-scroll  d-flex align-items-center flex-column align-content-center animated flipOutX">
                    <h3>{this.props.title}</h3>
                    <small>{this.props.second}</small>
                    <p>{this.props.third}</p>
                </div>
            </div>
            
        )
    }
} */

class FlipX extends React.Component{
    constructor(props){
        super(props);
        this.over = this.over.bind(this);
        this.cerrar = this.cerrar.bind(this);
        this.arrayes = this.props.parrafos["steps"];
        this._child = React.createRef();
        this._childBackground = React.createRef();
    }
    componentDidMount(){

        var miButton = this._child.current;
        
        this.circle = document.createElement('div');

        miButton.appendChild(this.circle);
        console.log(miButton.clientWidth)
        this.d = Math.max(miButton.clientWidth, miButton.clientHeight);
       
        this.circle.style.width = '0px';
        this.circle.style.height = '0px';
        
    }

    over(e){
       
        var miBackgroundContent = this._childBackground.current;
        var miButton = this._child.current;
     
        
        var rect = miButton.getBoundingClientRect();
       
        this.circle.style.width = this.circle.style.height = this.d + 'px';
		this.circle.style.left = (e.clientX - rect.left + 2)  + 'px';
		this.circle.style.top = (e.clientY - rect.top+ 2)  + 'px';
		this.circle.classList.add('ripple');
        
        var that = this;

		setTimeout(function () {  that.circle.classList.remove('ripple');},800);

        
        var eye = parseInt(e.pageY) - 50;
        var exe = parseInt(e.pageX) - 50; 

        miBackgroundContent.style.top = eye + "px";
        miBackgroundContent.style.left = exe + "px";  
        var ids = parseInt(this.props.ids);
        setTimeout(function(){

            that.circle.style.width = '0px';
            that.circle.style.height = '0px';   
            miBackgroundContent.style.top = "0px";
            miBackgroundContent.style.left = "0px";  
            
            miBackgroundContent.classList.remove("img-big-dos");
            miBackgroundContent.classList.add("img-big");

            var imagenSelect = miBackgroundContent.getAttribute('img-select')
            var fondImgReturn = document.querySelectorAll(".img-return");

            fondImgReturn[ids].setAttribute('src', imagenSelect) 

        },400) 
          

    }
     cerrar(e){
        var miBackgroundContent = this._childBackground.current;
        var miButton = this._child.current;
       
        var stules = miButton;
        miBackgroundContent.style.top = stules.getBoundingClientRect().top + "px";
        miBackgroundContent.style.left = stules.getBoundingClientRect().left + "px"; 

      
        miBackgroundContent.classList.remove("img-big");
        miBackgroundContent.classList.add("img-big-dos");
    }
    render() {

        var s = this.arrayes;
        return(
            <div class="background-item ">
                <div class="img-scroll">     
                    <img alt="ss" class="img-b" src={this.props.img} />
                </div>
                <div class="item-scroll  d-flex align-items-center flex-column justify-content-center animated flipOutX">
                    <h3>{this.props.title} </h3>
                    <strong>{this.props.second}</strong>
                    <div class="press" iss="da" onClick={this.over} ref={this._child}>
                        {this.props.third}  
                    </div>
                </div>
                <div class="button-background d-flex align-items-center justify-content-center animated zoomIn">
                    <button class="btn-op-5">Ver</button>
                </div>
                <div class="img-over  animated " img-select={this.props.img} ref={this._childBackground}>
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                                <img src="" class="img-return img-fluid" />
                            </div>
                            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                <p>
                                    <div class="closed btn btn-dark"  onClick= {this.cerrar} >cerrar</div>
                                </p>
                                
                                {s.map((element, i) => <p>{element}</p>)}
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}
export {FlipX};
/* 
export const FlipX = ({title, second, third, img, ids}) =>  
<div class="background-item ">
    <div class="img-scroll">     
        <img alt="ss" class="img-b" src={img} />
    </div>
    <div class="item-scroll  d-flex align-items-center flex-column justify-content-center animated flipOutX">
        <h3 class="press" iss="da" onClick={

            (e) => { 
                console.log(e.target)
                var ids = parseInt(1);
                var fondImg = document.querySelectorAll(".img-over");

                miBackgroundContent.classList.remove("img-big-dos");
                miBackgroundContent.classList.add("img-big");
                console.log(miBackgroundContent)
                var imagenSelect = miBackgroundContent.getAttribute('img-select')
                var fondImgReturn = document.querySelectorAll(".img-return");
                fondImgReturn[ids].setAttribute('src', imagenSelect)
                console.log(imagenSelect)

            }

        }>{title} </h3>
        <small>{second}</small>
        <p>{third}</p>
       
    </div>
    <div class="button-background d-flex align-items-center justify-content-center animated zoomIn">
        <button class="btn-op-5">Ver</button>
    </div>
    <div class="img-over animated" img-select={img}>
        <div class="close" 
            onClick= {
                () => {
                    var ids = parseInt(1);
                    var fondImg= document.querySelectorAll(".img-over");

                    miBackgroundContent.classList.remove("img-big");

                    miBackgroundContent.classList.add("img-big-dos");
                } 
            }
        >cerrar</div>
        <img src="" class="img-return" />
    </div>
    
</div> */