import React from 'react';

export const validateEmpty = (parametro) => {
	return (parametro == "");
}

export const validateEmptyHtml = (parametro) => {
	var imprime = "";
	if (validateEmpty(parametro)) {
	    imprime = <h2 style={{ "backgroundColor": "red" }}>VACIO</h2>;
	} else {
	    imprime = "";
	}
	return imprime
}

export const validateEmail = (email) => {

    var expr = /^[a-zA-Z0-9_\.\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+$/;
    
	expr = expr.test(email);
    var imprime = true;
	if (!expr) {
		imprime = false;
	} else {
		imprime = true;
	}
	
	return imprime;
}
