import React from 'react';

export const DificaComponente = <div className="row">
        <div className="col-lg-12 col-md-12 col-xs-12">
          <div className="card-cv-content card-cv-1 device--alt" >
            <div className="card-body dummy ">
              <h5 className="card-title dummy__item">Inmobiliaria </h5>
              <p className="card-text dummy__item">
                Creación de cms Crud y servicios web RestFul  con <strong> php puro, REACT Redux y servicios JSON, consultas de tablas (inner join), triggers, procedures y funciones con mysql</strong> . 
              </p>
              <p className="card-text dummy__item">
                Creación de algoritmos matemáticos para simuladores financieros a valor presente VAN y test fit (<strong> algoritmos de ordenación y combinaciones</strong> ) con image mapster y <strong> javascript</strong> . Impresión de inventarios en pdf con php.
                https://neonbo.com/moli/simulador-corrida/
              </p>
              <p className="card-text dummy__item">
                Normalización de tablas en mysql, importación datos de Excel (csv), implementación de json servicios web. Conexiones de datos <strong> php y Mysql (PDO Y MYSQLI_CONNECT). Mantenimiento y creación de cms framework CodeIgniter</strong> .
              </p>
              <p className="card-text dummy__item">
                Desarrollo de aplicaciones con widgets app <strong> Dart firebase y Flutter en android</strong> . https://neonbo.com/moli/app-web/
              </p>
              <p className="card-text dummy__item">
                 Maquetación de sitios web y PDF con Bootstrap, css,  css3,  Sass,  html5  React y jquery. Implementación de Google maps, lightbox, galerias,  captcha, parallax, etc.
              </p>
            </div>
          </div>
        </div>
      </div>;