import React from 'react';
export const BlogComponent = 
<div className="row">
    <div className="col-lg-12 col-md-12 col-xs-12">
        <div className="card-cv-content card-cv-3 device--alt" >
            <div className="card-body dummy">
                <h5 className="card-title dummy__item">Desarrollador Blog</h5>
                <p className="card-text dummy__item">
                    Generación de estilos <strong> CSS puro</strong> . Utilización de estilos avanzados como <strong> position, float y flex box</strong> . Implementación <strong> CMS blog blogger</strong>  de google http://verchitoss.blogspot.com.
                </p>
            </div>
        </div>
    </div>
</div>;