import React from 'react';
export const NeonboComponente = 
<div className="row">
    <div className="col-lg-12 col-md-12 col-xs-12">
        <div className="card-cv-content card-cv-2 device--alt" >
            <div className="card-body dummy">
                <h5 className="card-title dummy__item">Desarrollador web neonbo</h5>
                <p className="card-text dummy__item">
                    Creación de CMS, paginación y módulo de búsqueda para publicaciones en el sitio, incorporando componentes con datos estructurados, con <strong>jquery php y mysql</strong>. 
                </p>
                <p className="card-text dummy__item">
                    Creación de aplicación web para dibujar <a href="/graffiti">Link graffiti </a>. Análisis, reestructuración y diseño de la  aplicación para dibujar.  implementación de <strong>módulos y prototipos enJavascript</strong>  utilizando jquery avanzado.
                (https://neonbo.com/aplicacion-de-pinceles-para-dibujar-online/) 
                
                </p>
                <p className="card-text dummy__item">
                    Creación de aplicación web para crear imágenes con texto con código reusable de la aplicación de dibujo (http://neonbo.com/crear-imagenes-de-cumpleanos-online).
                </p>
            
            </div>
        </div>
    </div>
</div>