export let departamentosTable = 
    [
        {"propiedad":"id",
        "valor":"",
        "title": "id",
        "type":"text",
        "show_table":false,
        "modificar_edit":true,
        "place_holder": "nombre**",
        "valida_campo_extra": "20" },
        
        {"propiedad":"piso_departamento",
        "valor":"",
        "title": "Piso departamento",
        "type":"text",
        "show_table":true,
        "modificar_edit":true,
        "place_holder": "nombre**",
            "valida_campo_extra": "230" },

        {"propiedad":"torre",
        "valor":"",
        "title": "torre",
        "type":"text",
        "show_table":false,
        "modificar_edit":true,
        "place_holder": "nombre**",
            "valida_campo_extra": "40" },

        {"propiedad":"no_departamento",
        "valor":"",
        "title": "No departamento",
        "type":"text",
        "show_table":true,
        "modificar_edit":true,
        "place_holder": "nombre**",
            "valida_campo_extra": "230" },

        {"propiedad":"recamaras",
        "valor":"",
        "title": "Recamaras",
        "type":"text",
        "show_table":true,
        "modificar_edit":true,
        "place_holder": "nombre**",
            "valida_campo_extra": "50" },

        {"propiedad":"banios",
        "valor":"",
        "title": "Baños",
        "type":"text",
        "show_table":true,
        "modificar_edit":true,
        "place_holder": "nombre**",
            "valida_campo_extra": "60" },

        {"propiedad":"area_habitable",
        "valor":"",
        "title": "Área habitable",
        "type":"text",
        "show_table":false,
        "modificar_edit":true,
        "place_holder": "nombre**",
        "valida_campo_extra": "70" }, 

        {"propiedad":"terraza",
        "valor":"",
        "title": "Terraza",
        "type":"text",
        "show_table":false,
        "modificar_edit":true,
        "place_holder": "nombre**",
        "valida_campo_extra": "70" },

        {"propiedad":"precio",
        "valor":"",
        "title": "Precio",
        "type":"text",
        "show_table":false,
        "modificar_edit":true,
        "place_holder": "nombre**",
        "valida_campo_extra": "70" },   
        
        {"propiedad":"no_cajones",
        "valor":"",
        "title": "Estacionamiento",
        "type":"text",
        "show_table":false,
        "modificar_edit":true,
        "place_holder": "nombre**",
        "valida_campo_extra": "70" },  

         {"propiedad":"id_estatus",
        "valor":"",
        "title": "estatus",
        "type":"text",
        "show_table":false,
        "modificar_edit":true,
        "place_holder": "nombre**",
        "valida_campo_extra": "70" },  
    ];

