import React from 'react';

class Container extends React.Component {
	render() {
		return (
			<div className="container container_1024">
				{ this.props.children }
			</div>
		)
	}
}

class ContainerUnico extends React.Component {
	render() {
		return (
			<div className="container-fluid  mt-4 over-flawer">
				{ this.props.children }
			</div>
		)
	}
}

class Row extends React.Component {
	render() {
		return (
			<div className="row p-1">
				{ this.props.children }
			</div>
		)
	}
}
class Cols12 extends React.Component {
	render() {
		return (
			<div className="col-lg-12 p-1">
				{ this.props.children }
			</div>
		)
	}
}
class Cols6 extends React.Component {
	render() {
		return (
			<div className="col-lg-6 p-1">
				{ this.props.children }
			</div>
		)
	}
}
class Cols4 extends React.Component {
	render() {
		return (
			<div className="col-lg-4 p-1">
				{ this.props.children }
			</div>
		)
	}
}

class Cols2 extends React.Component {
	render() {
		return (
			<div className="col-lg-4 p-1">
				{ this.props.children }
			</div>
		)
	}
}


export {Row, Container, Cols12, ContainerUnico, Cols6, Cols2};