import React from 'react';
import {validateEmpty, validateEmptyHtml, validateEmail} from './validaciones/email.js';
import {defItems} from '../datos/inde.js';
import axios from 'axios';

class InputFormImage extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			erroreInput: "",
			showFormImage: false,
			inputImage: '',
			imgAdd: 'add-img',
			btnImg: 'add-img-block'
		}
		
		this.defaultValueInput = props.defaultValueInput;
		this.imgs = "https://david-perez-portafolio.neonbo.com/json/imagenes-blog/"+ this.defaultValueInput;
		this.refInput = props.refInput;
		this.tipos = props.tipos;
		this.titleCampo = props.titleCampo;
		this.nameCampo = props.nameCampo;
		this.funcError = this.funcError.bind(this);
		this.shows = this.shows.bind(this);
		this.hides = this.hides.bind(this);
		this.file = null;
		// Campo si se editara
		this.editProps = props.edited;
		this.saveEditProps = props.saveEdit;
		this.saveEditClase = "dd-edit";
		
		this.handleSubmit = this.handleSubmit.bind(this);

		this.fileInput = React.createRef();
        /**
         *  Agregar contenido a variable
         */
		defItems.items[0][this.nameCampo] = props.defaultValueInput;
	
	}
	shows(){
	
		this.setState({
			imgAdd: 'add-img-block',
			btnImg: 'add-img'
		})
		
	}
	hides(){
	
		this.setState({
			imgAdd: 'add-img',
			btnImg: 'add-img-block'
		})
		
	}

	

	funcError(e){
		/* let parametro = e.target.value;
		let errorHtml = "";

		
		//this.file = e.target.myimage.files[0];
		if (validateEmpty(parametro)) {
			defItems.items[0][this.nameCampo] = "";
			errorHtml = "Esta vacio";
		} else {
			defItems.items[0][this.nameCampo] = parametro;
			errorHtml = "";
		}

		this.setState({
			erroreInput: errorHtml,
		}) 
		if(this.saveEditProps===false){
			
			if(this.editProps===false ){
				this.saveEditClase = "no-edit";
			} else {
				this.saveEditClase = "dd-vacio";
			}
		} else {
			this.saveEditClase = "dd-vacio";
		}
		*/
	}

	handleSubmit(e) {
		e.preventDefault();

		const data = new FormData() 
    data.append('file', this.fileInput.current.files[0]);
    let url = "https://david-perez-portafolio.neonbo.com/json/guardar_imgs.php";

		axios.post(url, data, { 
			
    }).then(res => { 
			
			var sa = res.request.response;
			    sa = JSON.parse(sa);

			let parametro = sa[0]['nombre'];
			let errorHtml = "";
			defItems.items[0][this.nameCampo] = parametro;

			this.imgs = "https://david-perez-portafolio.neonbo.com/json/imagenes-blog/"+ parametro;
		
			this.setState({
				erroreInput: errorHtml,
				imgAdd: 'add-img',
				btnImg: 'add-img-block'
			})
    })
   	

		//formD.append('pic1',this.file);

		/* formD.append('image', {
			uri: this.file,
			type: 'image/jpg',
			name: 'image.jpg',
		  }); */

	
        /* formD.append('pic1', this.file, this.file.name); */

		
		/* let fileN = document.getElementById('fileN')
		var formData = new FormData(); */

		/* formData.append("image",this.fileInput.current.files[0]); */

		
/* 
		formData.append('image', {
			uri: this.fileInput.current.files[0],
			name: this.fileInput.current.files[0].name,
			type: 'image/jpg'
		  });
		  console.log(this.fileInput.current.files[0]);
		console.log(formData);

		fetch('https://david-perez-portafolio.neonbo.com/json/guardar_imgs.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			body: formData
		}).then(res => res.text()).then((result) => {
				console.log(result)
				//console.log(result.items)
			},
			(error) => {
				console.log(error);
		}) */

	

		/*
		fetch('https://david-perez-portafolio.neonbo.com/json/guardar_imgs.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			body: formData
		}).then(response => response.text())
		.then(contents => console.log(contents))
		.catch(() => console.log("Can’t access   response Blocked by browser?"));*/


	}
	render() {
		
		const {erroreInput, imgAdd, btnImg} = this.state;

		if(this.saveEditProps===false){
			
			if(this.editProps===false ){
				this.saveEditClase = "no-edit";
			} else {
				this.saveEditClase = "dd-vacio";
			}
		} else {
			this.saveEditClase = "dd-vacio";
		}
		
		return (
			<div className={` row ${this.saveEditClase}`}>
			    <label className="col-lg-3 col-md-3 col-form-label"></label>
				<div className={`btn col-lg-6 col-md-6  ${btnImg}`} >
					<div className="moda" >
						<div className="modal-dialog" role="document">
							<div className="modal-content modal-change">
								<div className="modal-header">
									<h5 className="modal-title">Imagen de usuario</h5>
								</div>
								<div className="modal-body">
									<img src={this.imgs} className="w-50 inline-block" />
								</div>
								<div className="modal-footer">
									<button className="btn btn-crud" onClick={this.shows}>Agregar imagen</button>
								</div>
							</div>
						</div>
					</div>
				
				</div>
				<div className={`ninguna col-lg-6 col-md-6  ${imgAdd}`}>

					<form onSubmit={this.handleSubmit} id="myform" >
						<div className="moda" >
							<div className="modal-dialog card-crud " role="document">
								<div className="modal-content modal-change">
									<div className="modal-header">
										<div className="modal-title"><label className="col-lg-3 col-md-3 col-form-label">{this.titleCampo}</label></div>
									
									</div>
									<div className="modal-body">
										<input className="form-control form-control-sm form-filter" type={this.tipos} name={this.nameCampo}  onChange={this.funcError} id="fileN"  ref={this.fileInput} />

										<label className="col-lg-3 col-md-3 col-form-label">{erroreInput}</label>

										
									</div>
									<div className="modal-footer">
										<input type="submit" value="Enviar" className="btn btn-crud form-filter" />
										<button className="btn btn-crud" onClick={this.hides}>Cancelar</button>
										
									</div>
								</div>
							</div>
						</div>
					</form>
					
				</div>
				
				
			</div>
		)
	}
}

class InputForm extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			erroreInput: ""
		}
		this.defaultValueInput = props.defaultValueInput;
		this.refInput = props.refInput;
		this.tipos = props.tipos;
		this.titleCampo = props.titleCampo;
		this.nameCampo = props.nameCampo;
		this.funcError = this.funcError.bind(this);
		this.editProps = props.edited;
		this.saveEditProps = props.saveEdit;
		this.saveEditClase = "dd-edit";
        /**
         *  Agregar contenido a variable
         */
		defItems.items[0][this.nameCampo] = props.defaultValueInput;
	
	}
	funcError(e){
		
		let parametro = e.target.value;

		let errorHtml = "";

		if (validateEmpty(parametro)) {
			defItems.items[0][this.nameCampo] = "";
			errorHtml = "Esta vacio";
		} else {
			defItems.items[0][this.nameCampo] = parametro;
			errorHtml = "";
		}
		
		this.setState({
			erroreInput: errorHtml
		})
	}
	render() {
		
		const {erroreInput} = this.state;
		
		if(this.saveEditProps===false){
			
			if(this.editProps===false ){
				this.saveEditClase = "no-edit";
			} else {
				this.saveEditClase = "dd-vacio";
			}
		} else {
			this.saveEditClase = "dd-vacio";
			if(this.nameCampo=="id"){
				this.saveEditClase = "no-edit";
				defItems.items[0][this.nameCampo] = "1";

			}
		}
		
		return (
			<div className={` row ${this.saveEditClase}`}>
				<label className="col-lg-3 col-md-3 col-form-label">{this.titleCampo}</label>
				<div className="col-lg-8 col-md-8">
					<input className="form-control form-control-sm form-filter" type={this.tipos} name={this.nameCampo} defaultValue={this.defaultValueInput} ref={this.refInput}  onChange={this.funcError} />
				</div>
				<label className="col-lg-3 col-md-3 col-form-label">{erroreInput}</label>
			</div>
		)
	}
}

class InputFormEmail extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			erroreInput: ""
		}
		this.defaultValueInput = props.defaultValueInput;
		this.refInput = props.refInput;
		this.tipos = props.tipos;
		this.titleCampo = props.titleCampo;
		this.nameCampo = props.nameCampo;
		this.funcError = this.funcError.bind(this);
		this.editProps = props.edited;
		this.saveEditProps = props.saveEdit;
		this.saveEditClase = "dd-edit";
        /**
         *  Agregar contenido a variable
         */
		defItems.items[0][this.nameCampo] = props.defaultValueInput;
	
	}
	funcError(e){
		
		let parametro = e.target.value;

		let errorHtml = "";

		if (validateEmpty(parametro)) {
			defItems.items[0][this.nameCampo] = "";
			errorHtml = "Esta vacio";
		} else {
			defItems.items[0][this.nameCampo] = parametro;
			if (!validateEmail(parametro)) {
				defItems.items[0][this.nameCampo] = "";
				errorHtml = "No es un email";
			} else {
				defItems.items[0][this.nameCampo] = parametro;
				errorHtml = "";
			}
		}
		
		this.setState({
			erroreInput: errorHtml
		})
	}
	render() {
		
		const {erroreInput} = this.state;
		
		if(this.saveEditProps===false){
			
			if(this.editProps===false ){
				this.saveEditClase = "no-edit";
			} else {
				this.saveEditClase = "dd-vacio";
			}
		} else {
			this.saveEditClase = "dd-vacio";
			if(this.nameCampo==="id"){
				this.saveEditClase = "no-edit";
				defItems.items[0][this.nameCampo] = "1";

			}
		}
		
		return (
			<div className={` row ${this.saveEditClase}`}>
				<label className="col-lg-3 col-md-3 col-form-label">{this.titleCampo}</label>
				<div className="col-lg-8 col-md-8">
					<input className="form-control form-control-sm form-filter" type={this.tipos} name={this.nameCampo} defaultValue={this.defaultValueInput} ref={this.refInput}  onChange={this.funcError} />
				</div>
				<label className="col-lg-3 col-md-3 col-form-label">{erroreInput}</label>
			</div>
		)
	}
}


class InputSelectForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			erroreInput: "",
			itemSelect: [],
		}
		this.defaultValueInput = props.defaultValueInput;
		this.refInput = props.refInput;
		this.tipos = props.tipos;
		this.titleCampo = props.titleCampo;
		this.nameCampo = props.nameCampo;
		this.editProps = props.edited;
		this.saveEditProps = props.saveEdit;
		this.saveEditClase = "dd-edit";

		this.jsonItem = {};
		
		this.funcError = this.funcError.bind(this);
		defItems.items[0][this.nameCampo] = props.defaultValueInput;
	}
	funcError(e){
		let parametro = e.target.value;
		let errorHtml = "";
		defItems.items[0][this.nameCampo] = parametro;
		
		this.setState({
			erroreInput: errorHtml
		})

	}
	componentDidMount(){
		fetch("https://david-perez-portafolio.neonbo.com/json/select.php", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				table: this.nameCampo,
			})
		}).then(res => res.json()).then((result) => {
			
			this.jsonItem = result.items;
			this.setState({
				itemSelect: result.items
			})
		});
	}
	render() {
		const {erroreInput, itemSelect} = this.state;

		let option;
		let optionDos;

		const optionsMap = (
			itemSelect.map((index, i) => 
			    {
					if(index.id!=this.defaultValueInput){
						return <option value={index.id}>
								{index.nombre}
							</option>
					}
					
				}
			)
		);
		const optionsDefault = (
			itemSelect.map((index, i) => 
			    {
					if(index.id==this.defaultValueInput){
						return <option value={index.id}>
								{index.nombre}
							</option>
					}
				}
			)
		);
		

		if(this.saveEditProps===false){
			
			if(this.editProps===false ){
				this.saveEditClase = "no-edit";
			} else {
				this.saveEditClase = "dd-vacio";
			}
		} else {
			this.saveEditClase = "dd-vacio";
		}

		return (
			<div className={` row ${this.saveEditClase}`}>
				<label className="col-lg-3 col-md-3 col-form-label">{this.titleCampo}</label>
				<div className="col-lg-8 col-md-8">
					<select className="form-control form-control-sm form-filter niveles" type={this.tipos} name={this.nameCampo} ref={this.refInput} onChange={this.funcError}>
						{optionsDefault}
						{optionsMap}
						
					</select>
				</div>
				<label className="col-lg-3 col-md-3 col-form-label">{erroreInput}</label>
			</div>
		)
	}
}

class InputSelectRadio extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			erroreInput: "",
			itemSelect: [],
			selectedOption: props.defaultValueInput,
		}
		this.defaultValueInput = props.defaultValueInput;
		this.refInput = props.refInput;
		this.tipos = props.tipos;
		this.titleCampo = props.titleCampo;
		this.nameCampo = props.nameCampo;
		this.editProps = props.edited;
		this.saveEditProps = props.saveEdit;
		this.saveEditClase = "dd-edit";

		this.jsonItem = {};
		
		this.funcError = this.funcError.bind(this);
		defItems.items[0][this.nameCampo] = props.defaultValueInput;
	}
	funcError(e){
		let parametro = e.target.value;
		let errorHtml = "";
		defItems.items[0][this.nameCampo] = parametro;
		
		this.setState({
			erroreInput: errorHtml,
			selectedOption: e.target.value
		})
	}
	componentDidMount(){
		fetch("https://david-perez-portafolio.neonbo.com/json/select.php", {
			method: 'POST',
			type: "json",
			headers: {
				'Content-Type': 'application/json',
				'Accept':'application/json',
			},
			body: JSON.stringify({
				table: this.nameCampo,
			})
		}).then(res => res.json()).then((result) => {
			
			this.jsonItem = result.items;
			this.setState({
				itemSelect: result.items
			})
		});
	}
	render() {
		const {erroreInput, itemSelect, selectedOption} = this.state;
		const radiossMap = (
			itemSelect.map((index, i) => 
			    {
					
					//if(index.id!=this.defaultValueInput){
					return <div className="d-inline ml-2">
								<input type="radio" className="form-filter" checked={this.state.selectedOption === index.id} value={index.id} onChange={this.funcError} /><span className="ml-1">{index.nombre}</span>
							</div>
					//}
					
				}
			)
		);
		/* const radiossDefault = (
			itemSelect.map((index, i) => 
			    {
					if(index.id==this.defaultValueInput){
						return <div >
								<input type="radio" 
					                checked={this.state.selectedOption === "Male"}
								
								/>{index.nombre}
							</div>
				}
			)
		); */
		

		if(this.saveEditProps===false){
			
			if(this.editProps===false ){
				this.saveEditClase = "no-edit";
			} else {
				this.saveEditClase = "dd-vacio";
			}
		} else {
			this.saveEditClase = "dd-vacio";
		}

		return (
			<div className={` row ${this.saveEditClase}`}>
				<label className="col-lg-3 col-md-3 col-form-label">{this.titleCampo}</label>
				<div className="col-lg-8 col-md-8">
					<form className="form-control form-control-sm form-filter niveles">
						{radiossMap}
						
					</form>
				</div>
				<label className="col-lg-3 col-md-3 col-form-label">{erroreInput}</label>
				
			</div>
		)
	}
}

export {InputForm, InputSelectForm, InputFormImage, InputFormEmail, InputSelectRadio};