import React from 'react';
import {Helmet} from 'react-helmet';
import './styles.css';
import fondoImg from '../img/fondo-body.jpg';
import fondoImgDos from '../img/fondotren-blur.jpg';
class MyComponentGraffiti extends React.Component {
    
    componentDidMount(){
      document.body.style.backgroundImage = 'url('+fondoImg+')';
    }
    componentWillUnmount(){
      document.body.style.backgroundImage = 'url('+fondoImgDos+')';
    }
  
    render() {
        return (
            <div class="W-100">
                <Helmet>
                    <title>Pinceles app</title>
                    <meta name="description" content="Aplicacion para crear dibujos" />
                </Helmet>
                <h5  class="p-cv-center">Creador graffiti code</h5>
                <div class="embed-responsive embed-responsive-1by1 iframe-height">
                    <iframe class="embed-responsive-item" src="https://neonbo.com/crear-graffiti/"></iframe>
                </div>
            </div>
        )
    }
}

export {MyComponentGraffiti};