import React from "react";
import { Helmet } from "react-helmet";
import { FlipX } from "./animaciones/flipx.js";
import { FlipY } from "./animaciones/flipy.js";
import "./style.css";

import fondoImg from "../img/fondo-body.jpg";
import fondoImgDos from "../img/fondotren-blur.jpg";

import imgPdf1 from "../img/creador-pdf-departamentos-bosque.JPG";
import imgPdf2 from "../img/creador-pdf-departamentos-nova.JPG";
import imgPdf3 from "../img/creador-pdf-departamentos.JPG";

import web1 from "../img/dcid.jpg";
import web2 from "../img/ankora.jpg";
import web3 from "../img/pantallasa.jpg";

import web4 from "../img/verchitos.jpg";

const datas = [
  {
    name: "Pdf 1",
    imagen: imgPdf1,
    liga: "url",
    titleUno: "Financiero",
    titleDos: "Abrir",
    steps: [
      "lorem Possimus non repudiandae error quibusdam quo iure. Ratione necessitatibus et earum ea est et quos et. ",
      "Dicta laudantium quaerat illum debitis qui a possimus quia error. Voluptas et et qui quam a quo illo sit. Delectus vel in iusto cum..",
    ],
  }, 
  {
    name: "Pdf 2",
    imagen: imgPdf2,
    liga: "url",
    titleUno: "Financiero",
    titleDos: "Abrir",
    steps: [
      "lorem lorem .",
      "Dicta laudantium quaerat illum debitis qui a possimus quia error. Voluptas et et qui quam a quo illo sit. Delectus vel in iusto cum.",
    ],
  },
  {
    name: "Pdf 3",
    imagen: imgPdf3,
    liga: "url",
    titleUno: "Financiero",
    titleDos: "Abrir",
    steps: [
      "Commodi quisquam dicta corrupti voluptates reiciendis ut qui autem neque. Velit doloremque quae porro id et eveniet deserunt. ",
      "Dicta laudantium quaerat illum debitis qui a possimus quia error. Voluptas et et qui quam a quo illo sit. Delectus vel in iusto cum..",
    ],
  }, 
];

const datas_web = [
  {
    name: "WEB 1",
    imagen: web3,
    liga: "https://neonbo.com/moli/inmob",
    titleUno: "Cms y página web",
    titleDos: "Abrir",
    steps: [
      "Preheat the oven to 350s99 degrees.",
      "Spread the olive oil around a glass baking dish. Add the salmon, garlic, and pine nuts to the dish.",
      " Remove from oven and let cool for 15 minutes. Add the lettuce and serve.",
    ],
  },
  {
    name: "WEB 2",
    imagen: web1,
    liga: "http://www.dcidesarrollos.mx/",
    titleUno: "Página web",
    titleDos: "Abrir",
    steps: [
      "lorem lorem .",
      "Top them with lettuce, tomatoes, and cheese. Bake for 15 minutes. Add the yellow squash and put back in the ovrve.",
    ],
  },
  {
    name: "WEB 3",
    imagen: web2,
    liga: "http://ankora.mx/",
    titleUno: "Página web",
    titleDos: "Abrir",
    steps: [
      "Cook the fish on the grill until hot. Place the fish on the 3 tortillas. Bake for 15 minutes. Add the yellow squash and put back in the oserve.",
      "Top them with lettuce, tomatoe Remove from oven and let cool for 15 minutes. Add the lettuce and serve.",
    ],
  },
  {
    name: "WEB 4",
    imagen: web4,
    liga: "http://verchitoss.blogspot.com/",
    titleUno: "Página web",
    titleDos: "Abrir",
    steps: [
      "Cook the fish on the grill until hot. Place the fish on the 3 tortillas. Bake for 15 minutes. Add the yellow squash and put back in the oserve.",
      "Top them with lettuce, tomatoe Remove from oven and let cool for 15 minutes. Add the lettuce and serve.",
    ],
  },
];


class Maquetacion extends React.Component {
  constructor(props){
    super(props);
    
  }
  componentDidMount() {
    document.body.style.backgroundImage = "url(" + fondoImgDos + ")";
  }
  componentWillUnmount() {
    document.body.style.backgroundImage = "url(" + fondoImgDos + ")";
  }

  render() {
    return (
      <div class="W-100">
        <Helmet>
          <title>MAQUETACIO</title>
          <meta name="description" content="Aplicacion para crear dibujos" />
        </Helmet>
        <h2 class="p-cv-center">Maquetación pdf</h2>
        <div class="container mt-5 mb-1">
          <div class="row">
              {datas.map((item, i) => 
                  <div class="col-lg-4 col-md-4 mt-4">
                    <FlipX
                      title={item.name}
                      liga={item.liga}
                      second={item.titleUno}
                      third={item.titleDos}
                      img={item.imagen}
                      ids={i}
                      parrafos={item}
                    />
                  </div>
              )}
          </div>
        </div>
        <h2 class="p-cv-center mt-5 mb-1">Maquetación web</h2>
        <div class="container">
          <div class="row">
            {datas_web.map((item, i) => <div class="col-lg-4 col-md-4 pt-5">
              <FlipY
                  title={item.name}
                  liga={item.liga}
                  second={item.titleUno}
                  third={item.titleDos}
                  img={item.imagen}
                  ids={i}
                  parrafos={item}
                /></div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export { Maquetacion };
