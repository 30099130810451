import React from 'react';
import './index_f.css';
import { Helmet } from 'react-helmet';
class FlutterInmobilaria extends React.Component {
    constructor(props){
      super(props)
      this.nameTable = /*props.nameTable*/this.props.match.params.id_seccion;
      this.state = {
        select: "",
      }
      
    }
    componentDidMount(){
      if(this.nameTable==="galeria"){
        this.setState({
          select: "http://galeria-flutter.neonbo.com/#",
        });
      } else if(this.nameTable==="cronometro"){
        
        this.setState({
          select: "https://neonbo.com/moli/cronometro/",
        });
      } else {
        this.setState({
          select: "https://neonbo.com/moli/inmobiliaria-1/",
        });
      }
    }
    componentWillReceiveProps(nextProps) {
      if(this.nameTable !== nextProps.match.params.id_seccion){

        this.nameTable = nextProps.match.params.id_seccion;
        
        if(this.nameTable==="galeria"){
          this.setState({
            select: "https://galeria-flutter.neonbo.com/",
          });
        } else if(this.nameTable==="cronometro"){
        
          this.setState({
            select: "https://neonbo.com/moli/cronometro/",
          });
        } else {
          this.setState({
            select: "https://neonbo.com/moli/inmobiliaria-1/",
          });
        }
      }

    }
    render() {
      const {select} = this.state;
      return (
        <div class="W-100">
          <Helmet>
            <title>App flutter</title>
            <meta name="description" content="App flutter web" />
          </Helmet>
          <h2  class="p-cv-center">Aplicación en flutter</h2>
          <div class="deviceWrap ipad-landscape" id="devices">
            
            <iframe class="device"  src={select}></iframe>
            
            
          </div>
        </div>
      )
    }
}
export {FlutterInmobilaria};