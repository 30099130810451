import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';
import {Row, Cols12, ContainerUnico} from './semantica/container.js';
import {Naver} from './menus.js';
import MyComponent from './crud/Controla.js';

import { MyComponentPinceles } from './entertaiment/brushes.js';
import { MyComponentGraffiti } from './entertaiment/graffiti.js';
import { MyComponentImgCreator } from './entertaiment/create-img.js';
import { Maquetacion } from './design/maquetacion.js';

import {CurriculoDav} from './cv/index.js';
import {FlutterInmobilaria} from './flutter/inmobiliaria.js';
import {SimuladorVan} from './simuladores/van.js'
 


class App extends React.Component{
  constructor(props){
    super(props);
    this.state = {
    
      showCrudActive:true,
      // Ocultar y mostrar menu crud css
      cssCrudCollapseContent: true,

      // Ocultar y mostrar menu crud css
      cssFlutterCollapseContent: true,

      // Ocultar y mostrar menu entretenimiento css
      cssEntretenimientoCollapseContent: true,
    }
    // x Activas sidebar
    this.activeFunc = this.activeFunc.bind(this);
    
    // Ocultar y mostrar clicked methodo crud
    this.activeCrudCollapse = this.activeCrudCollapse.bind(this);
    //Ocultar y mostrar clicked methodo flutter
    this.activFlutterCollapse = this.activFlutterCollapse.bind(this);

    // Ocultar y mostrar clicked methodo enetretenimiento
    this.activeEntretenimientoCollapse = this.activeEntretenimientoCollapse.bind(this);

    this.nameTable = "usuarios";
  }
  activeFunc(){
    this.setState({
      showCrudActive: !this.state.showCrudActive,
    });
  }
  activeCrudCollapse(){
    this.setState({
      cssCrudCollapseContent: !this.state.cssCrudCollapseContent,
    });
  }
  activeEntretenimientoCollapse(){
    this.setState({
      cssEntretenimientoCollapseContent: !this.state.cssEntretenimientoCollapseContent,
    });
  }

  activFlutterCollapse(){
    this.setState({
      cssFlutterCollapseContent: !this.state.cssFlutterCollapseContent,
    });
  }

  componentDidMount(){
    this.setState({
      nameSeccion: "Usuarios",
    });
  }

  render() {
    const {showCrudActive, cssCrudCollapseContent,  cssEntretenimientoCollapseContent, cssFlutterCollapseContent} = this.state;
      
    return (
      <div className="wrapper">
      <Router>
        <nav id="sidebar" className= {`sincss ${showCrudActive ? "active" : "noactive"}`}>
          <div className="sidebar-header d-flex align-items-start flex-column align-items-center" align="center">
        
               <img src="https://david-perez-portafolio.neonbo.com/img/davidcv.jpg" alt="" className="img-circle " />
              <small className="mt-3">David Pérez </small>
              <small>Programador React, Flutter y Php </small>
          </div>
          <ul className="list-unstyled components"  id="homeSubWeb">
              <li>
                <Link to='/cv'  
                className="flex-icon-sidebar"> <div class="fa fa-2x fa fa-user-plus "></div> <div className="lista-sidebar" onClick={this.activeFunc}>Perfil</div></Link>
              </li>
            
              <li class="active">
                  
                  <a onClick={this.activeCrudCollapse} data-toggle="collapse" aria-expanded="false" className="flex-icon-sidebar">
                    
                    <div class="fa fa-2x fa-book "></div>
                    <div className=" lista-sidebar">Crud React</div>
                  
                  </a>
                  <ul class={`colapsep-pre  list-unstyled ${cssCrudCollapseContent ? "collapse-d-crud" : "no-collapse-d-crud"}`} id="homeSubmenu">
                    <li><Link to='/cms/usuarios' onClick={this.activeFunc}>Usuarios </Link></li>
                    <li><Link to='/cms/departamentos' onClick={this.activeFunc}>Departamentos </Link></li>
                  </ul>
              </li>
              <li>
                  <Link to='/simulador' className="flex-icon-sidebar"> <div class="fa fa-2x fa-building"></div> <div className="lista-sidebar" onClick={this.activeFunc}>Simulador</div></Link>
              </li>
              
              <li class="active">
                  
                  <a onClick={this.activFlutterCollapse} data-toggle="collapse" aria-expanded="false" className="flex-icon-sidebar">
                    
                    <div class="fa fa-2x fa-book "></div>
                    <div className=" lista-sidebar">Flutter</div>
                  
                  </a>
                  <ul class={`colapsep-pre  list-unstyled ${cssFlutterCollapseContent ? "collapse-d-flutter" : "no-collapse-d-flutter"}`} id="homeSubmenu">
                    <li><Link to='/app-flutter/inmobiliaria' onClick={this.activeFunc}>Inmobiliaria </Link></li>
                    <li><Link to='/app-flutter/galeria' onClick={this.activeFunc}>Galería </Link></li>
                    <li><Link to='/app-flutter/cronometro' onClick={this.activeFunc}>Cronómetro </Link></li>
                  </ul>
              </li>
               <li>
                   <Link to='/maquetacion' className="flex-icon-sidebar"><div class="fa fa-2x fa-square-o"></div><div className="lista-sidebar" onClick={this.activeFunc}> Maquetación</div> </Link>
              </li>
              <li class="active">
                  
                  <a onClick={this.activeEntretenimientoCollapse} data-toggle="collapse" aria-expanded="false" className="flex-icon-sidebar"><div class="fa fa-2x  fa-paint-brush"></div>
                  <div className="lista-sidebar">App dibujo</div></a>
                  <ul class={`colapsep-pre  list-unstyled ${cssEntretenimientoCollapseContent ? "collapse-d-entretenimiento" : "no-collapse-d-entretenimiento"}`} id="homeSubmenu">
                    <li><Link to='/graffiti' onClick={this.activeFunc}>Graffiti </Link></li>
                    <li><Link to='/pinceles' onClick={this.activeFunc}>Pinceles </Link></li>
                    <li><Link to='/creator-imagenes' onClick={this.activeFunc}>Imagenes </Link></li>
                  </ul>
              </li>
          </ul>
        </nav>
    
        <div id="content">
          <Naver activeFunc={this.activeFunc}  showCrudActive={showCrudActive}/>
          <ContainerUnico>
            <Row className="p-0">
              <Cols12 className="p-0">
                <Switch>
                  <Route exact path="/" component={CurriculoDav}/>
                  <Route exact path="/cms/:id_seccion" component={MyComponent}/>
                  <Route exact path="/app-flutter/:id_seccion" component={FlutterInmobilaria}/>
                  <Route exact path="/simulador" component={SimuladorVan} />
                  <Route exact path="/cv" component={CurriculoDav}/>
                  <Route exact path="/graffiti" component={MyComponentGraffiti}/>
                  <Route exact path="/pinceles" component={MyComponentPinceles}/>
                  <Route exact path="/creator-imagenes" component={MyComponentImgCreator}/>
                  <Route exact path="/maquetacion" component={Maquetacion}/>
                </Switch>
              </Cols12>
            </Row>
          </ContainerUnico>
        </div>
        </Router>
      </div>
    )
  }
}

export default App;
