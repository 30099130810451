import React from 'react';

import {usuariosTable} from './tables/usuarios.js';
import {departamentosTable} from './tables/departamentos.js';
export let camposBaseDatos = {};

export let tablaSelec = usuariosTable;
export let defItems = {};
export let identidadConst;
/**
 * @param  {} tables El nombre de la tabla de la base de datos para actualizar al nuevo props
 */
export function datosFun(tables){

	defItems = {};
	identidadConst = {};
	tablaSelec = {};
	camposBaseDatos = {};
	
    if(tables=="departamentos"){
		tablaSelec = departamentosTable;
	}
	if(tables=="usuarios"){
		tablaSelec = usuariosTable;
	}
	identidadConst = tablaSelec;
	// Nombre del objeto table que se sellecciono
	for (const key in identidadConst) {
		//Recorremoos todos los elemenros que pertenecen a cada campo para despues inseretarlos en un nuevo array
		/**
		 *  modificar_edit: true
			place_holder: "nombre**"
			propiedad: "id"
			show_table: false
			title: "id"
			type: "text"
			valida_campo_extra: "20"
			valor: ""
		 */
		if (identidadConst.hasOwnProperty(key)) {
			const element = identidadConst[key];
			var propiedadArrayes = identidadConst[key]['propiedad'];
			// Propiedad del elemento
			//Filtros para los editables pero ya no se requirio revisar despues
			//if(identidadConst[key]['modificar_edit']==true){
				camposBaseDatos[propiedadArrayes]="";
			//}
		} 
	}
	// Todas las propiedades(campos de la base de datos) de los campos de la tabla del archivos de tablas
	defItems = {
		items: [
			camposBaseDatos
		]
	};
}

export function resetArray(){
	var itemsARRAY = defItems.items[0];
	for (var property in itemsARRAY) {
		if(property==="id"){
			itemsARRAY[property]="ww";
		} else {
			itemsARRAY[property]="";
		}
	}
}




