import React from 'react';
import './style_s.css';
import { Helmet } from 'react-helmet';
/* <img src="http://davidpepez.com/img/formula-van.jpg" class="mt-4 mb-4" /> */
class SimuladorVan extends React.Component {
    render() {
      return (
        <div class="W-100">
          <Helmet>
            <title>Simulador VAN</title>
            <meta name="description" content="Simulador financiero para calcular el descuento a valor presente comparando dos propuestas similares..." />
            <meta name="author" content="David Pérez Pérez" />
          </Helmet>
          <div class="p-cv">
            <h2>Valor actual neto Van</h2>
          </div>
          <div class="card mt-3 mb-3">
            <p class="text-black m-2">
              Simulador financiero para calcular el porcentaje de descuento a valor presente de cada departamento.
            </p>
          </div>
          <div class="embed-responsive embed-responsive-1by1 iframe-hei">
            <iframe class="embed-responsive-item" src="https://neonbo.com/moli/simulador-corrida/" ></iframe>
          </div>
        </div>
      )
    }
  }
  export {SimuladorVan};