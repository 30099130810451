import React from 'react';
import {Helmet} from 'react-helmet';
import fondoImg from '../img/fondo-body.jpg';
import fondoImgDos from '../img/fondotren-blur.jpg';


class MyComponentImgCreator extends React.Component {
  
    componentDidMount(){
      document.body.style.backgroundImage = 'url('+fondoImg+')';
    }
    componentWillUnmount(){
      document.body.style.backgroundImage = 'url('+fondoImgDos+')';
    }
    
    render() {
      return (
        <div class="W-100">
          <Helmet>
            <title>Pinceles app</title>
            <meta name="description" content="Aplicacion para crear dibujos" />
          </Helmet>
          <h2  class="p-cv-center">Creador de imágenes</h2>
          <div class="embed-responsive embed-responsive-1by1 iframe-height" >
            <iframe class="embed-responsive-item" src="https://neonbo.com/crear-imagenes-de-cumpleanos-online.php"></iframe>
          </div>
        </div>
      )
    }
  }

  export {MyComponentImgCreator};