
import React from 'react';

export  const Naver = ({activeFunc, showResultsActive}) => {
    return (
        <nav className="navbar navbar-expand-lg ">
          <div className="container-fluid">

              <div className="navbar-header">
                  <button type="button" id="sidebarCollapse" className="navbar-btn" onClick={activeFunc} className= {` navbar-btn ${showResultsActive ? "active" : "noactive"}`}>
                      <span></span>
                      <span></span>
                      <span></span>
                  </button>
              </div>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <div className="nav navbar-nav ml-auto">
                    <div className="container-fluid">
                      <div className="row">
                        
                      </div> 

                    </div>
                  </div>
              </div>
          </div>
        </nav>
    ) 
  };
