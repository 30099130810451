import React from 'react';
import { Helmet } from 'react-helmet';
import { datosFun, defItems, identidadConst, resetArray } from './datos/inde.js';
import { ButtonActualizar, ButtonCancelar, ButtonSave, HeaderCms, HeaderLimite } from './extras/botones.js';
import { InputForm, InputFormEmail, InputFormImage, InputSelectForm, InputSelectRadio } from './form/forms.js';

import './style_c.css';
/* import fondoImg from '../img/fondo-body.jpg';
import fondoImgDos from '../img/fondotren-blur.jpg'; */
/**
 * 
 * @param {Object} items: Todo el objeto de los campos de la base de datos de la tabla 
 */

const CrudMvc = ({ clickedDelete, items, clickedEditar, clickAddNew, showFormOrTable, clickCancelar, showButtonSave, clickSaveEdit, clickSaveNew, nameSeccion, botonLimit,  selectLimite, changeValBusqueda, contadorLim}) => {
      var aryes = [];
      var objCompleted = {};
      /**
       * ARRAY PRINCIPAL
       * items
       */
      items.map((item, i) => {
        //Se agre un array dentro del array princpal que imprimira la tabla del encabezado, inputs de busquedas, items de toda la tabla
        aryes.push([])
        for (var property in item) {//Leemos primer elemento del item de la base de datos y le insertamos mas elemntos del objeto identidadConst para que podamos imprimir o visualizar los campos que queramos en la tabla
          objCompleted = {};//sE CREA UN OBJETO para insertarlo en el nuevo aryes  
          // eslint-disable-next-line no-loop-func
          identidadConst.map((condi,k)=>{//Recorremos el objeto para insertar los nuevos elemntos
            for(var indexar in condi){

            
              if(condi['propiedad']==property){//Cuando el valodr de la propiedad es igual a la propieda del objeto de la base de datos, en ese momento, solo tomas los valores de la base de datos y se las insertamos al onjeto identidadConst a su vez con ese mismo objeto creamos otro objeto objCompleted para insertarlos aryes
                if(indexar==="valor"){
                  objCompleted[indexar] = item[property];
                } else if(indexar==="propiedad"){
                  objCompleted[indexar] = property;
                } else {
                  objCompleted[indexar] = condi[indexar];
                }
              }


            }
          });
          aryes[i].push(objCompleted)
        }
      })
    return (() => {
      
      

      function maysPrimera(string){
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      
      var nombreSeccion = maysPrimera(nameSeccion);
  
      if (showFormOrTable) {
        return (
          <div className="card-crud p-1 tableIn  m-auto">
            <div className="w-100 pt-2 card-header">
              <HeaderCms title={nombreSeccion} clickAddNew={clickAddNew} />
            </div>
            <div className=" pt-2 card-body">
              <table className="table table-sm table-crud" >
                <thead>
                  <tr>
                    {
                      aryes[0].map((inde,j)=>{
                        if(inde.show_table===true){
                          return(
                            <th >{inde.title}</th>
                          )
                        }
                      })
                    }
                    <th colspan="2">Acciones</th>
                  </tr>
                  <tr>
                  {
                    aryes[0].map((inde,j)=>{
                      if(inde.show_table===true){
                        return(
                          <td><input className="form-control form-filter form-control-sm" type="text" onChange={changeValBusqueda.bind(this, inde.propiedad) } />  </td>
                        )
                      }
                    })
                  }
                  <td colspan="2"></td>
            
                  </tr>
                  
                </thead>
                <tbody>
                  { 
                    aryes.map((maper, i)=>{
                      return(
                        <tr>
                          {
                            maper.map((inde,j)=>{
                              if(inde.show_table===true){
                                return(
                                  <td>{inde.valor}</td>
                                )
                              }
                            })
                          }
                          {
                            (() => {
                              if(maper[0].valor!=="ninguno"){
                                return(<td className="text-center">
                                  <i className="fa fa-trash fa-2x" onClick={() => clickedDelete(maper[0].valor)}></i>
                                </td>)
                              }
                            })()
                          }
                          {
                            (() => {
                              if(maper[0].valor!=="ninguno"){
                                return(<td className="text-center">
                                  <i className="fa fa-pencil fa-2x" dss={maper[0].valor} onClick={() => clickedEditar(maper[0].valor)}></i>
                                </td>)
                              }
                            })()
                          }
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
              <HeaderLimite selectLimite={selectLimite}  botonLimit={botonLimit} contadorLim={contadorLim}/>
            </div>
  
  
          </div>
  
        )
      } else {
        return (
          <div className="w-100 d-flex justify-content-center tableIn ">

            <div className=" card-crud">
              <div className=" card-header" >
                {
                  showButtonSave ? <h5>Registro nuevo</h5> : <h5>Editar</h5>
                }
              </div>
              
              {
                aryes.map((maper, i)=>{
                  return(
                    <div className="w-100 card-body">
                      {
                        maper.map((inde,j)=>{

                          var inputForm = "";
                     
                          if(inde.type === "select" ){
                            inputForm = <InputSelectForm tipos={inde.type} titleCampo={inde.title} nameCampo={inde.propiedad}   defaultValueInput={inde.valor} placeholderName="mail@mail.com" saveEdit={showButtonSave}  edited={inde.modificar_edit} />;
                          } else if(inde.type === "file" ){

                            inputForm = <InputFormImage tipos={inde.type} titleCampo={inde.title} nameCampo={inde.propiedad}   defaultValueInput={inde.valor} placeholderName="mail@mail.com" saveEdit={showButtonSave}  edited={inde.modificar_edit} />;

                          } else if(inde.type === "email" ){

                            inputForm = <InputFormEmail tipos={inde.type} titleCampo={inde.title} nameCampo={inde.propiedad}   defaultValueInput={inde.valor} placeholderName="mail@mail.com" saveEdit={showButtonSave}  edited={inde.modificar_edit} />;

                          } else if(inde.type === "radio" ){

                            inputForm = <InputSelectRadio tipos={inde.type} titleCampo={inde.title} nameCampo={inde.propiedad}   defaultValueInput={inde.valor} placeholderName="mail@mail.com" saveEdit={showButtonSave}  edited={inde.modificar_edit} />;

                          } else {
                            inputForm = <InputForm tipos={inde.type} titleCampo={inde.title} nameCampo={inde.propiedad}   defaultValueInput={inde.valor} placeholderName="mail@mail.com" saveEdit={showButtonSave}  edited={inde.modificar_edit} />;
                          }
                          
                            return(
                              <div className="w-100">
                                  {inputForm}
                              </div>
                            )
                          
                          
                        })
                      }
                    </div>
                  )
                })
              }
              <div className="form-group row card-footer">
                <div className="col-lg-2 col-sm-2 ">
                  {
                    showButtonSave ?
                      <ButtonActualizar  clickSaveNew={clickSaveNew} />
                      :
                      <ButtonSave clickSaveEdit={clickSaveEdit} />
                  }
                </div>
                <div className="col-lg-2 col-sm-2 ">
                  <ButtonCancelar className="btn btn-dark" clickCancelar={clickCancelar} />
                </div>
              </div>
            </div>
          </div>
        )
      }
    })();
  };
  /**
   * @param  {string} seccion - Nombre de la seccion a la que pertenece para modificar, contar obtener,
   * @param  {int} id - Id de la fila que se editara
   * @param  {Object} datos - Objeto para insertar los elementos de la fila hacia la base de datos
   * @param  {string} nameTable - Nombre de la tabla de la base de datos a la seccion que pertenece
   * @param  {Object} condiciones - Objeto de busqueda y de limites. Esta es la primera llamada a realizar par apode contar
   */
  const getContacts = async (seccion, id, datos, nameTable, condiciones) => {
    return fetch("https://david-perez-portafolio.neonbo.com/json/conexion.php", {
      method: 'POST',
      type:"json",
      headers: {'Content-Type': 'application/json','Accept': 'application/json'}, 
      body: JSON.stringify({ seccion: seccion, id: id, datos: datos, tables: nameTable, condiciones: condiciones })
    }).then(res => res.json())
  };
  
  
  
  class MyComponent extends React.Component{
    constructor(props) {
      super(props);
  
      this.state = {
        error: null,
        isLoaded: false,
        items: [],
        count: 1,
        /**
         * @showFormOrTable Muestra la taba de formulatio o los items
         * @type bool
         */
        showFormOrTable: true,
        showButtonSave: true,
      
        contadorLim: "",
        showSpain: false,
       
      };
    
      this.clickedDelete = this.clickedDelete.bind(this);
      this.clickedEditar = this.clickedEditar.bind(this);
  
      this.serviciJson = this.serviciJson.bind(this);
      this.clickCancelar = this.clickCancelar.bind(this);
      this.clickAddNew = this.clickAddNew.bind(this);
  
      this.clickSaveNew = this.clickSaveNew.bind(this);
      this.clickSaveEdit = this.clickSaveEdit.bind(this);

      this.selectLimite = this.selectLimite.bind(this);
      this.botonLimit = this.botonLimit.bind(this);
      this.limite = 5;
      this.contLimit = 0;
      //Numero totales de campos de busqueda en los inputs o valores en cero
      this.contlonfitud = 0;
  
      this.changeValBusqueda = this.changeValBusqueda.bind(this);
      this.nameTable = /*props.nameTable*/this.props.match.params.id_seccion;
      this.nameSeccion = /*props.nameSeccion*/this.props.match.params.id_seccion;
      // updateValue solo sera usado como un elemento vacio para ser llamado a la funcion serviciJson() este objeto solo sera usado para insertar nuevos y actualizar datos 
      this.updateValue = {};
      // condiciones es usado para agregar condicones por ejemplo los limites y los campos de los formullarios que se tengan que filtrar 
      //{id: "", limite: 5, contadorLimite: 0, nombre_completo: "da", nombre_usuario: "go"}
      this.condiciones = {};
      this.copiaItems = "";
      this.cont = 0;
      this.tablesactual = "usuarios";
    }
  
 
    serviciJson = (seccion, id, datos, condiciones) => {
      /**
       * sPINER **********
       */
      this.setState({
        showSpain: true,
   
      });
      getContacts(seccion, id, datos, this.nameTable, condiciones)
      
        .then(
          (result) => {
         
            if(seccion == "obtUno") {
              this.setState({
                items: result.items,
                showFormOrTable: false,
                showButtonSave: false,
                isLoaded: true,
                showSpain: false,
              });
  
            } else if (seccion == "modificar") {
              this.showSpain = false;
              this.setState({
                //items: result.items,
                showFormOrTable: true,
                isLoaded: true,
                showSpain: false,
              });
            } else if (seccion == "contador") {
              
              this.showSpain = false;

              // Revisamos la longitud de los items y los imprimimos
              this.contlonfitud = parseInt(result.items.length);
              let limiteContaPower = this.condiciones['limite'] + this.condiciones['contadorLimite'];
              let limiteContaPowerDos = (limiteContaPower + 1) - this.condiciones['limite'];
              this.setState({
                contadorLim: limiteContaPowerDos + " a " +  limiteContaPower + " de " + this.contlonfitud,
              });
             
            } else if (seccion=="guardar") {
              this.showSpain = false;
              this.setState({
               
                showFormOrTable: true,
                //isLoaded: true,
                showSpain: false,
              });
              
  
            } else {
              this.showSpain = false;
              if (this.state.showFormOrTable == true) {

                let limiteContaPower = this.condiciones['limite'] + this.condiciones['contadorLimite'];

                /**
                * Numero anterior
                * 5 = 5 + 0   1 = (5 + 1) - 5 
                * 10 = 5 + 5  6 = (10 + 1) - 5
                * 15 = 5 + 10 11 = (15 + 1) - 5
                */
                let limiteContaPowerDos = (limiteContaPower + 1) - this.condiciones['limite'];
                if(limiteContaPower >= this.contlonfitud){
                  limiteContaPower = this.contlonfitud;
                }
                this.setState({
                  isLoaded: true,
                  items: result.items,
                  showFormOrTable: true,
                  showSpain: false,
                  contadorLim: limiteContaPowerDos + " a " +  limiteContaPower + " de " + this.contlonfitud,
                });
              };
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
        //.then(json => console.log(json))
        //.catch(error => console.log(error))
        
    }
    componentDidMount() {
    //  document.body.style.backgroundImage = 'url('+fondoImgDos+')';
      datosFun(this.nameTable)
      
      if(this.cont==0){
        this.condiciones["id"] = "";
        this.condiciones['limite'] = 5;
        this.condiciones['contadorLimite'] = this.contLimit;
        this.cont = this.cont + 1; 
      }
     
      this.serviciJson("contador", 0, this.updateValue, this.condiciones);
      this.serviciJson("obtener", 0, [], this.condiciones);  
    } 
    componentDidUpdate(prevProps, prevState){
      
    }
    clickedDelete(ss) {
      this.serviciJson("eliminar", ss, [],[]);
      this.serviciJson("contador", 0, this.updateValue, this.condiciones);
      this.serviciJson("obtener", 0, [], this.condiciones);
    }
    
    clickedEditar(vales) {
      this.serviciJson("obtUno", vales, [],[]);
    }
    clickCancelar() {
      this.state.showFormOrTable = true;
      resetArray();
      this.serviciJson("contador", 0, this.updateValue, this.condiciones);
      this.serviciJson("obtener", 0, [], this.condiciones);
    }
  
    clickAddNew() {
  
      this.setState({
        showSpain: true,
      });
      this.state.showFormOrTable = false;
      this.setState({
        
        // Valores de los datos que existen
        // Para agregar todos los campos en el formulario que existen en la base de datos que a la vez se filtraran y validaran que los campos existen con el onjeto identidadConst = tablaSelec; tabla seleccionada
        items: defItems.items,
        showFormOrTable: this.state.showFormOrTable,
        showButtonSave: true,
        showSpain: false,
      }, () => {
        //console.log('statee', this.state.items);
        this.valorOne = this.state.items;
        //console.log(this.valorOne);
      });
      //console.log(this.valorOne);
      const items = this.state.items;
    }
  
    clickSaveNew(item, i, e) {
      
      var contadorError = 0;
      var itemsARRAY = defItems.items[0];

      console.log(itemsARRAY)
      // campos a guardae de cada formulario lleno sobre el objeto
      for (var property in itemsARRAY) {
        if(itemsARRAY[property]==""){
          contadorError++;
        }
      }
      /**
       * @param  {} !(contadorError>0 - Valida sin ningun campo del formulario esta vacio, si hay campos vacion no se enviara la informacion
       */
      if (!(contadorError>0)) {
        this.state.showFormOrTable = true;
        this.serviciJson("guardar", 0, itemsARRAY,[]);
        this.condiciones['contadorLimite'] = 0;
        setTimeout(() => {
          resetArray();
          this.serviciJson("contador", 0, itemsARRAY, this.condiciones);
          this.serviciJson("obtener", 0, [], this.condiciones);
        }, 300);
        
      } else {
        alert("revice sus formulario")
      }
   
    }
  
    clickSaveEdit(item, i, e) {
     
      var contadorError = 0;
      var itemsARRAY = defItems.items[0];
  
      for (var property in itemsARRAY) {
        if(itemsARRAY[property]==""){
          contadorError++;
        }
      }
      
      var ids = itemsARRAY['id'];
      
      if (!(contadorError>0)) {
        this.state.showFormOrTable = true;
        this.serviciJson("modificar", ids, itemsARRAY,[]);
        this.condiciones['contadorLimite'] = 0;
        
        setTimeout(() => {
          resetArray();
          this.serviciJson("contador", 0, itemsARRAY, this.condiciones);
          this.serviciJson("obtener", 0, [], this.condiciones);
        }, 300);
        
      } else {
        alert("revice sus formulario")
      }
      
    }
    
    /**
     * @param  {} inputs
     */
    botonLimit(inputs){
      
  
      let lim = 0;
      let contMax = 0;
      //(this.contlonfitud); total de itwems
      let limiteBloquea = this.contlonfitud / this.limite;
   
      let floorLimite = Math.floor(limiteBloquea);
      
      if(limiteBloquea>floorLimite){
        contMax = (floorLimite + 1) - 1;
      } else {
        contMax = floorLimite - 1;
      }
      

      if(inputs=="menos") {
        
        
        
        /**
         * @param  {} this.contLimit>0 Para evitar errores antes del cero
         */
        if(this.contLimit > 0){
          this.contLimit--;
          if(this.contLimit===0){
            lim =  0;
          }else {
            lim =  this.contLimit * this.limite;
          }
        }

        
      } else {
       

        
        /**
         * La base de datos de  limite empieza a contar desde 0 hasta this.condiciones['limite'] 0 5 10 15 valor constante del select
         * SELECT * FROM departamentos limit 0, 5
         * SELECT * FROM departamentos limit 5, 5
         * SELECT * FROM departamentos limit 10, 5  
         * SELECT * FROM departamentos limit 15, 5    
         *                        0 1 2 3 4 
         * 5 = 5 * 1 = 0 + 1  ... 5 6 7 8 9 
         * 10 = 5 * 2 = 1 + 1 ... 10 11 12 13 14
         * 15 = 5 * 3 = 2 + 1 ... 15 16 17 18 19
         */
        // this.contLimit es la que ira cambiando como un valor global
        
        if(this.contLimit<contMax){
          this.contLimit++;
          // lim un valor que se reseteara para representar el valor 
           // 0 5 10 15 20 25
        } 
        
        lim =  this.contLimit * this.limite;
      } 
      this.condiciones['contadorLimite'] = lim;
      
      /**
       * @param  {} !(lim>this.contlonfitud si es diferen de mayor a cero, que obtenga los limites que siguen
       */
      /* if(!(lim > this.contlonfitud)){
        this.serviciJson("obtener", 0, this.updateValue, this.condiciones);
      } */
      if(this.contLimit<=contMax){                           
        this.serviciJson("obtener", 0, this.updateValue, this.condiciones);
      } 
    }
   
    changeValBusqueda(s, e){
      
      this.condiciones[s] = e.target.value;
      this.condiciones['contadorLimite'] = 0;
      this.serviciJson("contador", 0, this.updateValue, this.condiciones);
      this.serviciJson("obtener", 0, this.updateValue, this.condiciones);
    }
  
    selectLimite(e){
      this.contLimit = 0;
      this.condiciones['limite'] = parseInt(e.target.value);
      this.condiciones['contadorLimite'] = 0;
      this.limite = e.target.value;
      this.serviciJson("obtener", 0, this.updateValue, this.condiciones);
    }
    
    componentWillUnmount() {
      //document.body.style.backgroundImage = 'url('+fondoImg+')';
    }
    
    componentWillReceiveProps(nextProps) {
     

        if(this.nameTable !== nextProps.match.params.id_seccion){
          this.updateValue = {};
          this.condiciones = {};
          datosFun(nextProps.match.params.id_seccion)
          resetArray();
          this.contLimit = 0;
          //Numero totales de campos de busqueda en los inputs o valores en cero
          this.contlonfitud = 0;
          
          this.nameTable = nextProps.match.params.id_seccion;
          this.nameSeccion = nextProps.match.params.id_seccion;
          
          //if(this.cont==0){
            this.condiciones["id"] = "";
          this.condiciones['limite'] = 5;
          this.condiciones['contadorLimite'] = this.contLimit;
        // }
          this.serviciJson("contador", 0, this.updateValue, this.condiciones);
          this.serviciJson("obtener", 0, [], this.condiciones);
        }
      
    }
    
    render() {
      const { error, isLoaded, items, showFormOrTable, showButtonSave, contadorLim, showSpain, nameTables } = this.state;
  
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className="loaderOne">sssss</div>
      } else {
        return (
          
          <div className="crudFondo">
            <Helmet>
              <title>Crud - React</title>
              <meta name="description" content="Aplicacion para crear dibujos" />
            </Helmet>
             
            <h2 class="p-cv-center">Crud con React, Php y mysql</h2>
            <CrudMvc clickedDelete={this.clickedDelete} clickedEditar={this.clickedEditar} clickCancelar={this.clickCancelar} clickAddNew={this.clickAddNew} items={items} showFormOrTable={showFormOrTable}  showButtonSave={showButtonSave}  clickSaveEdit={this.clickSaveEdit} clickSaveNew={this.clickSaveNew}  botonLimit={this.botonLimit} selectLimite={this.selectLimite} nameSeccion={this.nameSeccion} changeValBusqueda={this.changeValBusqueda}  contadorLim={contadorLim}  />
            <div id="loader-wrapper" className= {`kjkjkjk ${showSpain ? "dd-block" : "dd-none"}`}><div id="loader" ></div></div>
          </div>
        )
      }
    }
  }


export default MyComponent;
  
