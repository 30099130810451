import React, { useState } from 'react';
import './css/animated.css';
import './css/flipy.css';
import './css/press-scroll.css';

/* 
class Anime1 extends React.Component {
    render(){
        return (
           
            <div class="d-inline-block background-item ">
                <div class="img-scroll">     
                    <img alt="ss" class="img-fluid img-b" src={this.props.img} />
                </div>
                <div class="item-scroll  d-flex align-items-center flex-column align-content-center animated flipOutX">
                    <h3>{this.props.title}</h3>
                    <small>{this.props.second}</small>
                    <p>{this.props.third}</p>
                </div>
            </div>
            
        )
    }
} */

class FlipY extends React.Component{
    constructor(props){
        super(props);
        this.over = this.over.bind(this);
        this.cerrar = this.cerrar.bind(this);
        this.arrayes = this.props.parrafos["steps"];
        this._child = React.createRef();
        this._childBackground = React.createRef();
        this.iframeHeight = document.querySelectorAll(".img-over-y");
    }
    resizeIframe = () => {

        var innerHei = window.innerHeight;
        this._childBackground.current.style.height = innerHei+"px";

    }
    componentDidMount(){

        var miButton = this._child.current;
        this.circle = document.createElement('div');
        miButton.appendChild(this.circle);
        this.d = Math.max(miButton.clientWidth, miButton.clientHeight);
        this.circle.style.width = '0px';
        this.circle.style.height = '0px';
        window.addEventListener("resize", this.resizeIframe)
    }

    over(e){
        var that = this;

		setTimeout(function () {  that.circle.classList.remove('ripple');},800);
        var innerHei = window.innerHeight;
        this._childBackground.current.style.height = innerHei + "px";
        console.log(innerHei);
       
        var miBackgroundContent = this._childBackground.current;
        var miButton = this._child.current;
     
        
        var rect = miButton.getBoundingClientRect();
       
        this.circle.style.width = this.circle.style.height = this.d + 'px';
		this.circle.style.left = (e.clientX - rect.left + 2)  + 'px';
		this.circle.style.top = (e.clientY - rect.top+ 2)  + 'px';
		this.circle.classList.add('ripple');
        
        

        
        var eye = parseInt(e.pageY) - 50;
        var exe = parseInt(e.pageX) - 50; 

        miBackgroundContent.style.top = eye + "px";
        miBackgroundContent.style.left = exe + "px";  
        var ids = parseInt(this.props.ids);

        var iframesCont = miBackgroundContent.getAttribute('img-select');

        var frameReturn = document.querySelectorAll(".frame-return");

        frameReturn[ids].setAttribute('src', iframesCont) 
       
        setTimeout(function(){

            that.circle.style.width = '0px';
            that.circle.style.height = '0px';   
            miBackgroundContent.style.top = "0px";
            miBackgroundContent.style.left = "0px";  
            
            miBackgroundContent.classList.remove("img-big-dos-y");
            miBackgroundContent.classList.add("img-big-y");
            window.scroll({top:0})
            setTimeout(function(){
            document.body.style.overflow = "hidden";
              }, 800);
        }, 400);
   
    }
     cerrar(e){
        var miBackgroundContent = this._childBackground.current;
        var miButton = this._child.current;
       
        var stules = miButton;
        miBackgroundContent.style.top = stules.getBoundingClientRect().top + "px";
        miBackgroundContent.style.left = stules.getBoundingClientRect().left + "px"; 


        miBackgroundContent.classList.remove("img-big-y");
        miBackgroundContent.classList.add("img-big-dos-y");

        document.body.style.overflow = "initial";
    }
    render() {

        var s = this.arrayes;
        console.log(this.props.liga)
        return(
            <div class="background-item-y">
                <div class="img-scroll-y">     
                    <img alt="ss" class="img-b" src={this.props.img} />
                </div>
                <div class="item-scroll-y  d-flex align-items-center flex-column justify-content-center animated flipOutY">
                    <h3>{this.props.title} </h3>
                    <strong>{this.props.second}</strong>
                    <div class="press" iss="da" onClick={this.over} ref={this._child}>
                        {this.props.third}  
                    </div>
                </div>
                <div class="button-background-y d-flex align-items-center justify-content-center animated zoomIn">
                    <button class="btn-op-5">Ver</button>
                </div>
                <div class="img-over-y  animated " img-select={this.props.liga} ref={this._childBackground}>
                        <div class="iframe-y">
                            <iframe src="" class="iframe-y-cont frame-return"></iframe>
                        </div>
                        <div class="closed-y btn btn-light"  onClick= {this.cerrar} >cerrar</div>
                </div>
            </div>
        )
    }
}
export {FlipY};

/*
export const FlipY =({title, second, third, img}) =>  
<div class="background-item-y mb-5 mt-1">
    <div class="img-scroll-y">     
        <img alt="ss" class="img-fluid img-b-y" src={img} />
    </div>
    <div class="item-scroll-y   d-flex align-items-center flex-column justify-content-center animated flipOutY">
        <h3>{title}</h3>
        <small>{second}</small>
        <p>{third}</p>
    </div>
    <div class="button-background-y d-flex align-items-center justify-content-center animated zoomIn">
        <button class="btn-op-5">Ver</button>
    </div>
    <div class="img-over  animated " img-select={this.props.img} ref={this._childBackground}>
        <div class="container">
            <div class="row">
                <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                    <img src="" class="img-return img-fluid" />
                </div>
                <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                    <p>
                        <div class="closed btn btn-light"  onClick= {this.cerrar} >cerrar</div>
                    </p>
                    
                    {s.map((element, i) => <p>{element}</p>)}
                </div>
                
                
            </div>
        </div>
    </div>
</div>
*/